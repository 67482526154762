import * as types from "./MenuTypes";

//Replace action name and update action types
export const getMenu = (payload, tableId) => ({
  type: types.GET_MENU_REQUEST,
  payload,
  tableId,
});

export const getMenuReceive = (payload) => ({
  type: types.GET_MENU_RECEIVE,
  payload,
});

export const setMenuVersion = (payload) => ({
  type: types.SET_MENU_VERSION,
  payload,
});

export const getComboMenuReceive = (payload) => ({
  type: types.GET_COMBO_MENU_RECEIVE,
  payload,
});

export const getMenuCategoryList = (categoryId) => ({
  type: types.GET_MENU_CATEGORY_LIST_RECEIVE,
  payload: categoryId,
});
export const onSearchMenu = (searchPhrase) => ({
  type: types.GET_MENU_ON_SEARCH,
  payload: searchPhrase,
});
export const onFilterMenuCategory = (payload) => ({
  type: types.FILTER_MENU_CATEGORY,
  payload,
});
export const onFilterMenuCombo = (payload) => ({
  type: types.FILTER_MENU_COMBO,
  payload,
});
export const clearMenu = () => ({
  type: types.CLEAR_MENU,
});
export const toggleCategoryHomeMenu = (payload) => ({
  type: types.SHOW_CATEGORY_HOME_MENU,
  payload,
});
