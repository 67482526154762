import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  filterBarContainer: {
    height: "100%",
  },
  bgColor: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    // padding: theme.spacing(0, 1.6),
    // [theme.breakpoints.up('sm')]: {
    //     padding: theme.spacing(0, 2),
    // },
    // [theme.breakpoints.up('md')]: {
    //     padding: theme.spacing(0, 12),
    // },
  },
  mobileBtn: {
    height: "33px",
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  btn: {
    height: "33px",
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.fontTitle,
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  sectionDesktop: {
    height: "100%",
    alignItems: "center",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  sectionMobile: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawer: {
    width: 295,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.common.background,
    width: 295,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  filterDrawerContainer: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 4, 0, 4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 8),
    },
  },
  filterDrawerTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2.4),
  },
  filterListContainer: {
    // padding: theme.spacing(2.4, 12, 0, 8),
  },
  nextAvailable: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.fontTitle,
    margin: theme.spacing(0.4, 0.4, 0.4, 0.7),
    fontWeight: 500,
    padding: theme.spacing(0.7, 1.6),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
  },

  catMobileBtn: {
    height: "33px",
    fontSize: theme.typography.pxToRem(10),
    backgroundColor: "transparent",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.fontTitle,
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    display: "flex",
    borderRadius: "0",
    boxShadow: "none",
    marginRight: "-20px",
    marginTop: "3px",
    paddingRight: "4px",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
  catBtn: {
    height: "33px",
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: "transparent",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.fontTitle,
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    borderRadius: "0",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
  categoryDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  leftDiv: {
    height: "100%",
    width: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMobileDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  scrollbarMobileDiv: {
    overflowX: "auto",
    display: "flex",
    whiteSpace: "nowrap",
    maxWidth: "90%",
    boxShadow: "none",
    marginLeft: "22px",
    "&::-webkit-scrollbar": {
      width: "0", // Hide the horizontal scrollbar
      height: "0", // Hide the vertical scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgray", // Customize scrollbar thumb color
    },
  },
  scrollableDiv: {
    overflowX: "auto",
    display: "flex",
    whiteSpace: "nowrap",
    boxShadow: "none",
    maxWidth: "98%",
    // scrollPaddingBottom: "10px",
    "&::-webkit-scrollbar": {
      width: "0", // Hide the horizontal scrollbar
      height: "0", // Hide the vertical scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgray", // Customize scrollbar thumb color
    },
  },
  item: {
    fontSize: theme.typography.pxToRem(14),
    padding: "8px", // Add padding to create spacing
    width: "auto",
    height: "100%",
    // paddingBottom: "0",
    fontFamily: "Poppins-Regular",
    "&:hover": {
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  boldText: {
    fontWeight: 600,
    textDecoration: "underline",
  },
}));

export default useStyles;
