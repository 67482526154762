import React from "react";
import IconButton from "@material-ui/core/IconButton";

const IconBtn = ({ text, handleClick, icon, endIcon, children, ...props }) => {
  return (
    <IconButton
      variant="contained"
      color="primary"
      onClick={handleClick}
      {...props}
    >
      {icon}
      {children}
    </IconButton>
  );
};

export default IconBtn;
