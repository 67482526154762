import React from "react";
import clsx from "clsx";
// import { Route, useLocation } from "react-router-dom";
// import clsx from 'clsx';

// Components
import Footer from "./../../../components/Footer/Footer";

// Styles
import useStyles from "./MainLayout.style.js";
import SiteFooter from "../../../components/SiteFooter/SiteFooter";
import { APP_NAME, SILVERBACK_APP_NAME } from "../../Constants";

const MainLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={clsx("scroll", classes.bodyContainerMainHeader)}>
        <div className={`${classes.contentContainer} container`}>{children}</div>
        {APP_NAME === SILVERBACK_APP_NAME && (
          // add footer for silverback only
          <SiteFooter />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MainLayout;
