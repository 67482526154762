import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
// Icons
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// Custom Hooks
import usePriceSummary from "./../../../CustomHooks/usePriceSummary";
import useCheckoutForm from "./../../../CustomHooks/useCheckoutForm";
import useResponsive from "./../../../CustomHooks/useResponsive";
// Controls
import Btn from "./../../../components/Controls/Button/Button";
// Components
import PriceSummary from "./../../../components/OrderSummary/PriceSummary/PriceSummary";
import OrderSummaryForm from "./../../../components/OrderSummary/OrderSummaryForm/OrderSummaryForm";
import PaymentButton from "./../../../components/PaymentButton";
// Utils
import { dispatchErrorMessageAsDialog } from "../../Shared";
// Constants
import { CURRENCY, ERROR_TYPES, MESSAGES, ORDER_TYPES } from "../../Constants";
// Styles
import useStyles from "./CartLayout.style.js";
import Coupons from "../../../components/OrderSummary/Coupons/Coupons";
import { updateDeliveryZoneLocation } from "../../../store/Location/LocationActions";
import PoweredBy from "../../../components/PoweredBy";

const CartLayout = ({ children }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const [deliveryAlertShown, setDeliveryAlertShown] = useState(false);
  const [triggerCheckout, setTriggerCheckout] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [drawer, toggleDrawer] = useState(false);
  const { hotelKey, hotel } = useSelector((state) => state.hotel);
  const locationState = useSelector((state) => state.location);
  const { checkoutDetails } = useSelector((state) => state.cart);
  const { formValues, setFormValues } = useCheckoutForm();
  const { isMobileView } = useResponsive();

  const { total, subTotal } = usePriceSummary();

  useEffect(() => {
    if (
      formValues.orderType === ORDER_TYPES.DELIVERY &&
      !deliveryAlertShown &&
      Number(subTotal) > 0
    ) {
      if (
        locationState?.isLocationProcessed &&
        Number(subTotal) < locationState?.currentDeliveryZone?.minimum_amount
      ) {
        const newZone = checkAndUpdateDeliveryZone();
        if (newZone) {
          return;
        }
        const { minimum_amount } = locationState.currentDeliveryZone;
        dispatchErrorMessageAsDialog({
          type: ERROR_TYPES.MIN_AMOUNT_ORDER,
          message: `${MESSAGES.INVALID_TOTAL_AMOUNT} ${CURRENCY.SIGN}${minimum_amount}`,
        });
        setDeliveryAlertShown(true);
        return;
      }
    }
  }, [formValues.orderType, locationState, subTotal, deliveryAlertShown]);

  useEffect(() => {
    checkAndUpdateDeliveryZone();
  }, [subTotal]);

  const checkAndUpdateDeliveryZone = () => {
    const availableDeliveryZones = hotel?.deliveryzones?.sort((a, b) => {
      return a.delivery_fee - b.delivery_fee;
    });
    if (!availableDeliveryZones) {
      return;
    }

    const newZone = availableDeliveryZones.find(
      (z) =>
        z.zone_area >= locationState.distance &&
        z.minimum_amount <= Number(subTotal)
    );
    if (newZone && locationState?.isLocationProcessed) {
      dispatch(updateDeliveryZoneLocation({ deliveryZone: newZone }));
      return true;
    }
  };

  const onChange = (fieldName, value) => {
    setFormValues({ [fieldName]: value });
  };

  useEffect(() => {
    setTriggerCheckout(false);
  });

  useEffect(() => {
    if (!locationState?.currentDeliveryZone) {
      return;
    }

    const { minimum_amount } = locationState.currentDeliveryZone;
    if (
      locationState?.isLocationProcessed &&
      Number(subTotal) > minimum_amount
    ) {
      setButtonDisabled(false);
    } else {
      if (checkoutDetails.orderType !== ORDER_TYPES.PICKUP) {
        setButtonDisabled(true);
      }
    }
  }, [locationState, subTotal]);
  return (
    <React.Fragment>
      <div className="scroll">
        <div className={clsx("container", classes.bodyContainer)}>
          <div
            className={clsx(classes.flexItemContainer, classes.leftContainer)}
          >
            {children}
          </div>
          <div
            className={clsx(classes.flexItemContainer, classes.rightContainer)}
          >
            <div>
              <Coupons />
            </div>
            <div className={classes.PriceSummaryDesktopContainer}>
              <PriceSummary triggerCheckout={triggerCheckout} />
            </div>
            {[`/${hotelKey}/cart/checkout`].indexOf(location.pathname) > -1 && (
              <OrderSummaryForm
                initialValues={formValues}
                onChange={onChange}
              />
            )}
            {isMobileView && <PoweredBy />}
          </div>
        </div>
        <div className={classes.footerContainer} />
        <div
          className={clsx(
            classes.footerContainer,
            classes.PriceSummaryMobileContainer,
            classes.fixed
          )}
        >
          <div className={classes.totalAmountContainer}>
            <Typography
              className={classes.totalAmountText}
              variant="caption"
              noWrap
            >{` ${"Price Summary"}`}</Typography>
            <Btn
              text={`${CURRENCY.SIGN}${total}`}
              endIcon={<ExpandLessIcon />}
              className={classes.totalAmountButton}
              handleClick={() => toggleDrawer(true)}
            />
          </div>

          <div className={classes.grow} />
          {location.pathname.lastIndexOf("/checkout") > -1 ? (
            isMobileView ? (
              <PaymentButton
                mobileButton
                className={`${classes.spBtn} ${classes.btn}`}
              />
            ) : null
          ) : (
            // <Link to={`/${hotelKey}/cart/checkout`}>
            <Btn
              text={"Checkout"}
              handleClick={() => setTriggerCheckout(true)}
              disabled={buttonDisabled}
              className={`${classes.spBtn} ${classes.btn}`}
            />
            // </Link>
          )}
        </div>
        {!isMobileView && <PoweredBy />}

        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={drawer}
          onClose={() => toggleDrawer(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {/* <Coupons /> */}
          <PriceSummary />
        </Drawer>
      </div>
    </React.Fragment>
  );
};

export default CartLayout;
