import * as types from "./LocationTypes";

export const getDistanceDuration = (payload) => ({
  type: types.DISTANCE_DURATION_REQUEST,
  payload,
});
export const getDistanceDurationReceive = (payload) => ({
  type: types.DISTANCE_DURATION_RECEIVE,
  payload,
});
export const updateDeliveryZoneLocation = (payload) => ({
  type: types.UPDATE_DELIVERY_ZONE_LOCATION,
  payload,
});
export const clearLocation = (payload) => ({
  type: types.CLEAR_LOCATION,
  payload,
});
export const clearDuration = () => ({
  type: types.CLEAR_DURATION,
});
export const checkForDeliveryLocation = () => ({
  type: types.CHECK_FOR_DELIVERY_LOCATION,
});
export const setSelectedAddress = (payload) => ({
  type: types.SET_SELECTED_ADDRESS,
  payload,
});

export const getLocationData = (location) => ({
  type: types.GET_LOCATION_DATA,
  payload: location,
});

export const setUserCountry = (country) => {
  return {
    type: types.SET_USER_COUNTRY,
    payload: country,
  };
};

export const setIsLocationInvalid = (payload) => ({
  type: types.SET_IS_LOCATION_INVALID,
  payload,
});

export const getUserCountryRequest = () => ({
  type: types.GET_USER_COUNTRY_REQUEST,
});

export const getUserCountrySuccess = (data) => ({
  type: types.GET_USER_COUNTRY_SUCCESS,
  payload: data,
});
