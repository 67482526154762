// Constants
import { MESSAGES } from "../../utils/Constants";

export const calculateDistanceDuration = async (hotel, distanationLocation, isCheck) => {
  return new Promise((resolve, reject) => {
    if (!window.google) {
      return reject("Google api is not initialized");
    }
    if (!hotel) {
      return reject("Hotel not found");
    }
    if (!hotel.latitude || !hotel.longitude) {
      return reject("Hotel location not found");
    }
    if (!distanationLocation.latitude || !distanationLocation.longitude) {
      return reject("Destination location not found");
    }
    const mapsApi = window.google.maps;
    const origin = new mapsApi.LatLng(hotel.latitude, hotel.longitude);
    const destination = new mapsApi.LatLng(
      distanationLocation.latitude,
      distanationLocation.longitude
    );
    const service = new mapsApi.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          if (!response?.rows?.[0].elements?.[0]) {
            return reject("No response found from google map");
          }

          const { status, distance, duration } = response.rows[0].elements[0];

          if (status === "ZERO_RESULTS") {
            return reject("No response found from google map");
          }
          if (!distance || !duration) {
            return reject("No response found from google map");
          }
          // if (distance) {

          const formattedDistance = Number((distance.value / 1000).toFixed());
          const formattedDuration = duration.text;

          const zone = hotel?.deliveryzones?.find(
            (z) => z.zone_area >= formattedDistance
          );

          if (!isCheck && !zone) {
            return reject(MESSAGES.INVALID_LOCATION);
          }
          // if ()
          resolve({
            distance: formattedDistance,
            duration: formattedDuration,
            zone: zone,
          });
          // } else {
          //   reject("Something went wrong while process your location");
          // }
          // resolve({
          //   distance: formattedDistance,
          //   duration: formattedDuration,
          // });
        } else {
          return reject("Something went wrong while process your location");
        }
      }
    );
  });
  // return new Promise((resolve, reject) => {
  //   if (!window.google) {
  //     reject("Google api is not initialized");
  //   } else if (!hotel) {
  //     reject("Hotel not found");
  //   } else if (!hotel.latitude || !hotel.longitude) {
  //     reject("Hotel location not found");
  //   } else if (
  //     !distanationLocation.latitude ||
  //     !distanationLocation.longitude
  //   ) {
  //     reject("Destination location not found");
  //   } else {
  //     const mapsApi = window.google.maps;
  //     const origin = new mapsApi.LatLng(hotel.latitude, hotel.longitude);
  //     const destination = new mapsApi.LatLng(
  //       distanationLocation.latitude,
  //       distanationLocation.longitude
  //     );
  //     const service = new mapsApi.DistanceMatrixService();
  //     service.getDistanceMatrix(
  //       {
  //         origins: [origin],
  //         destinations: [destination],
  //         travelMode: "DRIVING",
  //       },
  //       (response, status) => {
  //         if (status === "OK") {
  //           if (response?.rows?.[0].elements?.[0]) {
  //             reject("No response found from google map");
  //           } else {
  //             const { status, distance, duration } =
  //               response.rows[0].elements[0];

  //             if (status === "ZERO_RESULTS") {
  //               reject("No response found from google map");
  //             } else if (!distance || !duration) {
  //               reject("No response found from google map");
  //             } else {
  //               const formattedDistance = Number(
  //                 (distance.value / 1000).toFixed()
  //               );
  //               const formattedDuration = duration.text;

  //               const zone = hotel.deliveryzones.find(
  //                 (z) => z.zone_area >= formattedDistance
  //               );
  //               if (!zone) {
  //                 reject(MESSAGES.INVALID_LOCATION);
  //               }
  //               resolve({
  //                 distance: formattedDistance,
  //                 duration: formattedDuration,
  //               });
  //             }
  //           }
  //         } else {
  //           reject("Something went wrong while process your location");
  //         }
  //       }
  //     );
  //   }
  // });
};
