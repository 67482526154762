// Replace with your request types
export const UPDATE_CART = "UPDATE_CART";
export const PROCESS_CART = "PROCESS_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const SAVE_CHECKOUT_DETAILS = "SAVE_CHECKOUT_DETAILS";
export const CLEAR_CART = "CLEAR_CART";
export const PRICE_SUMMARY = "PRICE_SUMMARY";
export const GET_COUPON_REQUEST = "GET_COUPON_REQUEST";
export const GET_COUPON_RECEIVE = "GET_COUPON_RECEIVE";
export const SELECT_COUPON = "SELECT_COUPON";
export const CLEAR_COUPON = "CLEAR_COUPON";
export const SET_AVAILABLE_COUPONS = "SET_AVAILABLE_COUPONS";
export const CLEAR_AVAILABLE_COUPONS = "CLEAR_AVAILABLE_COUPONS";
export const VALIDATE_COUPON = "VALIDATE_COUPON";
export const SELECT_TABLE = "SELECT_TABLE";
export const CLEAR_TABLE_SELECTION = "CLEAR_TABLE_SELECTION";
export const SET_GUESTS_COUNT = "SET_GUESTS_COUNT";