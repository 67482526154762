import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAvailableCoupons,
  clearCoupon,
  setAvailableCoupons,
} from "../../../store/Cart/CartActions";
import { ALL_DELIVERY_TYPES, COUPONS } from "../../../utils/Constants";
import Btn from "../../Controls/Button/Button";
import Modal from "../../Material/Modal/Modal";
import CouponList from "../CouponList/CouponList";
import CouponSuccess from "../CouponSuccess/CouponSuccess";
import useStyles from "./Coupons.style";

const Coupons = () => {
  const modalInit = {
    type: "",
    show: false,
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modal, toggleModal] = useState(modalInit);
  const [applicableCoupons, SetApplicableCoupons] = useState([]);
  const { selectedCoupon, coupons, priceSummary, checkoutDetails, data } =
    useSelector((state) => state.cart);

  const clearCurrentCoupon = () => {
    dispatch(clearCoupon());
  };

  const checkItemDiscountApplicability = (coupon) => {
    const applicableItems = coupon?.items || [];
    const validItemsInCart = data?.filter((item) =>
      applicableItems.includes(item.itemId)
    );
    // This condition for: 100% discount item can have only 1 quantity of that particular item
    // if (
    //   applicableItems?.length === 1 &&
    //   coupon?.result?.DISCOUNT === 100 &&
    //   validItemsInCart?.length === 1 &&
    //   validItemsInCart[0]?.count > 1
    // ) {
    //   return false;
    // } else return !!validItemsInCart.length;
    return !!validItemsInCart.length;
  };

  // const getPrice = (coupon) => {
  //   if (coupon?.rules?.type === COUPONS.ITEM_DISCOUNT) {
  //     const applicableItems = JSON.parse(coupon?.items) || [];
  //     const validItemsInCart = data?.filter((item) =>
  //       applicableItems.includes(item.itemId)
  //     );
  //     let itemTotal = 0;
  //     validItemsInCart.forEach((item) => {
  //       itemTotal = itemTotal + item?.count * item?.sale_price;
  //     });
  //     return Number(itemTotal);
  //   } else {
  //     return Number(priceSummary?.subTotal);
  //   }
  // };

  const findApplicableCoupons = () => {
    dispatch(clearAvailableCoupons());
    const newCoupons = coupons?.filter(
      (c) =>
        Number(priceSummary?.subTotal) > Number(c?.rules?.MIN_ORDER_VALUE) &&
        (c?.rules?.APPLICABLE_FOR === checkoutDetails?.orderType ||
          c?.rules?.APPLICABLE_FOR === ALL_DELIVERY_TYPES) &&
        (c?.rules?.type === COUPONS?.ITEM_DISCOUNT
          ? checkItemDiscountApplicability(c)
          : true)
    );
    dispatch(setAvailableCoupons(newCoupons));
    SetApplicableCoupons(newCoupons);
  };

  const selectCoupenHandler = (applied) => {
    toggleModal({
      ...modal,
      show: false,
    });
    if (applied) {
      toggleModal({
        type: "success",
        show: true,
      });
    }
  };

  useEffect(() => {
    findApplicableCoupons();
  }, [coupons, priceSummary]);

  // useEffect(() => {
  //   if (condition) {

  //   }
  // }, [selectedCoupon])
  return (
    <div className={classes.root}>
      <Typography
        // color="primary"
        className={classes.headerTitle}
        variant="h2"
        noWrap
      >
        {"Coupons"}
      </Typography>
      <div className={`${classes.listContainerRow} align-items-center`}>
        <Typography
          // color="primary"
          className={`${classes.headerTitle} ${
            !selectedCoupon?.id && classes.headerTitleEmpty
          } m-0`}
          variant="h2"
          noWrap
        >
          {selectedCoupon?.id ? selectedCoupon.name : "Select coupon"}
        </Typography>
        <Btn
          text={!selectedCoupon?.id ? "Select" : "Remove"}
          className={`${classes.btn}`}
          handleClick={() =>
            !selectedCoupon?.id
              ? toggleModal({ type: "coupon", show: true })
              : clearCurrentCoupon()
          }
        />
      </div>
      <Modal
        customView={true}
        open={modal.show}
        handleClose={() =>
          toggleModal({
            type: "",
            show: false,
          })
        }
        modelContainer={
          modal.type === "success" && classes.orderTypeModalContainer
        }
      >
        {modal.type === "coupon" && (
          <CouponList
            handleClose={(d) => selectCoupenHandler(d)}
            coupons={applicableCoupons || []}
          />
        )}
        {modal.type === "success" && (
          <CouponSuccess
            discount={priceSummary?.discount || 0}
            handleClose={() =>
              toggleModal({
                type: "",
                show: false,
              })
            }
          />
        )}
      </Modal>
    </div>
  );
};

export default Coupons;
