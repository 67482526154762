import React, { useState } from "react";
// import clsx from 'clsx';

// Material
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// Icons
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
// Components
import Modal from "./../Material/Modal/Modal";
import Menu from "./../Menu/Menu";
// Controls
import Btn from "./../Controls/Button/Button";
// Styles
import useStyles from "./Footer.style.js";
import useDynamicHotelData from "../../CustomHooks/useDynamicHotelData";
import { useSelector } from "react-redux";
import { showSnackbarAction } from "../../store/Snackbar/SnackbarAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import useCheckoutForm from "../../CustomHooks/useCheckoutForm";
import OrderTypeDrawer from "../OrderTypeDrawer/OrderTypeDrawer";
import { dispatchErrorMessageAsWarning } from "../../utils/Shared";
// import { toggleCategoryHomeMenu } from "../../store/Menu/MenuActions";

const Footer = () => {
  const classes = useStyles();
  const [openModal, setModal] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const { hotelStatus, hotelOrderStatus, getOrderTypes } =
    useDynamicHotelData();
  const { hotelKey, hotel, tableId } = useSelector((state) => state.hotel);
  const { formValues } = useCheckoutForm();
  const state = useSelector((state) => state.cart);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { nextAvailableTime } = useSelector((state) => state.time);

  const validateCartRedirect = () => {
    if (formValues?.orderType === null && tableId == null) {
      setDrawerOpen(true);
    } else if (hotel?.openingAvailable === false && hotel?.is_preorder === 0) {
      dispatchErrorMessageAsWarning({
        title: "Alert",
        message:
          "we are currently closed. Kindly revisit during our designated opening hours.",
      });
    } else if (nextAvailableTime === null && getOrderTypes == []) {
      dispatchErrorMessageAsWarning({
        title: "Warning",
        message:
          "Unfortunately, we have ceased operations for today. We kindly request that you return tomorrow.",
      });
    } else if (
      formValues?.orderType === "DELIVERY" &&
      hotel?.deliveryAvailable === false &&
      hotel?.is_preorder === 0
    ) {
      dispatchErrorMessageAsWarning({
        titel: "Warning",
        message: "Delivery time is currently Finished",
      });
    } else if (
      formValues?.orderType === "PICKUP" &&
      hotel?.pickupAvailable === false &&
      hotel?.is_preorder === 0
    ) {
      dispatchErrorMessageAsWarning({
        title: "Warning",
        message: "Pickup time is currently finished",
      });
    } else {
      if (!hotelOrderStatus) {
        dispatch(showSnackbarAction(hotel?.online_inactive_reason, "Warning"));
        return true;
      }
      if (state.data.length === 0) {
        dispatch(showSnackbarAction("Cart is empty", "warning"));
        return true;
      }
      history.push(`/${hotelKey}/cart`);
    }
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.btnCon}>
          {/* {!hotel?.isCategoryBar === 0 && ( */}
          {hotel?.isCategoryBar != 1 && (
            <Btn
              text={"Categories"}
              handleClick={() => {
                // dispatch(toggleCategoryHomeMenu(true));
                setModal(true);
              }}
              endIcon={<ExpandLessOutlinedIcon />}
              className={classes.btn}
            />
          )}
          <Btn
            text={
              state.data.length > 0 ? `Cart (${state.data.length})` : "Cart"
            }
            handleClick={validateCartRedirect}
            startIcon={<LocalMallOutlinedIcon />}
            className={
              hotel?.isCategoryBar === 1 ? classes.spclBtn : classes.btn
            }
          />
        </Toolbar>
      </AppBar>

      <Modal
        title={"Main Menu"}
        open={openModal}
        handleClose={() => setModal(false)}
      >
        <Menu closeModal={() => setModal(false)} />
      </Modal>

      {drawerOpen ? (
        <OrderTypeDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Footer;
