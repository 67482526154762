import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Material
import Grid from "@material-ui/core/Grid";
// Components
import ChoiceItem from "./../ChoiceItem/ChoiceItem";
// Redux Actions
import { getMenuCategoryList } from "./../../store/Menu/MenuActions";
// Styles
import useStyles from "./Menu.style.js";
import { CircularProgress, Typography } from "@material-ui/core";

const Menu = ({ closeModal = () => {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { isLoading } = useSelector(state => state.loader)
  const menu = useSelector((state) => state.menu);
  const state = useSelector((state) => state.menu);

  const setMenuCategory = (categoryId) => {
    // console.log("category iddddddddd",categoryId)
    dispatch(getMenuCategoryList(categoryId));
    closeModal();
  };

  // console.log("combo categoriesss", menu)
  // console.log("combo categoryy", menu.category)
  return (
    <>
      {!state.categoryLoaded ? (
        <div className={classes.loaderDiv}>
          <div className={classes.loaderContainer}>
            <CircularProgress color="primary" size={60} thickness={4} />
          </div>
        </div>
      ) : (
        <div className={classes.menuContainer}>
          <Grid container spacing={2}>
            {menu?.comboCategories
              ?.filter(
                (item, index, self) =>
                  self.findIndex(
                    (i) => i?.category_name === item?.category_name
                  ) === index
              )
              .map((c) => (
                <Grid item key={c.combo_id} xs={12} sm={6}>
                  <ChoiceItem
                    catImage={c?.image}
                    title={c.category_name}
                    value={c.combo_id}
                    selected={menu.selectedCategory === c.combo_id}
                    handleClick={() => setMenuCategory(c.category_name)}
                    desc={c?.description}
                  />
                </Grid>
              ))}
            {menu.category.map((c, i) => (
              <Grid item key={i} xs={12} sm={6}>
                <ChoiceItem
                  catImage={c.imageURL}
                  title={c.label}
                  value={c.categoryId}
                  selected={menu.selectedCategory === c.categoryId}
                  handleClick={setMenuCategory}
                  desc={c?.description}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
  // return (
  //     <div className={classes.menuContainer}>
  //         <Grid container spacing={2}>
  //             {menuItemsArray.map((menuItem, i) => <Grid item key={i} xs={12} sm={6}>
  //                 <ChoiceItem title={menuItem} value={menuItem} selected={menu === menuItem} handleClick={(m) => setMenu(m)} />
  //             </Grid>)}
  //         </Grid>

  //     </div>
  // );
};

export default Menu;
