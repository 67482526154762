import { useCallback } from "react";
import { useSelector } from "react-redux";

function useProduct() {
  const { hotel } = useSelector((state) => state.hotel);

  // const originalPrice = useMemo(() => {
  //   if(!product || !hotel){
  //       return 0;
  //   }
  //   const calTax = ( Number(product.sale_price) * 3) / 23
  //   const tax = hotel.tax_exclusive === 1 ? (Number(product.sale_price) - calTax) : Number(product.sale_price)
  //   return tax.toFixed(2) || 0
  // },[product,hotel]);

  // useEffect(() => {
  //   setState(product || {});
  // }, [product]);

  const getOriginalPrice = useCallback(
    (price) => {
      if (!price || !hotel) {
        return 0;
      }
      const calTax = (Number(price) * 3) / 23;
      // console.log("call tax", calTax);
      const tax =
        hotel.tax_exclusive === 1 ? Number(price) - calTax : Number(price);
      // console.log("taxxxxxxxxx", tax);
      return tax.toFixed(2) || 0;
    },
    [hotel]
  );

  return {
    getOriginalPrice,
  };
  // return {
  //     ...state,
  //     sale_price : originalPrice
  // }
}

export default useProduct;
