// Replace with your request types
export const DISTANCE_DURATION_REQUEST = "DISTANCE_DURATION_REQUEST";
export const DISTANCE_DURATION_RECEIVE = "DISTANCE_DURATION_RECEIVE";
export const UPDATE_DELIVERY_ZONE_LOCATION = "UPDATE_DELIVERY_ZONE_LOCATION";
export const CLEAR_LOCATION = "CLEAR_LOCATION";
export const CLEAR_DURATION = "CLEAR_DURATION";
export const CHECK_FOR_DELIVERY_LOCATION = "CHECK_FOR_DELIVERY_LOCATION";
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
export const GET_LOCATION_DATA = "GET_LOCATION_DATA";
export const SET_USER_COUNTRY = "SET_USER_COUNTRY";
export const GET_USER_COUNTRY_REQUEST = "GET_USER_COUNTRY_REQUEST";
export const GET_USER_COUNTRY_SUCCESS = "GET_USER_COUNTRY_SUCCESS";
export const SET_IS_LOCATION_INVALID = "SET_IS_LOCATION_INVALID";
