import * as types from "./MenuTypes";

const INITIAL_STATE = {
  hotelKey: null,
  selectedCategory: null,
  category: [],
  categoryLoaded: false,
  searchPhrase: "",
  combo: [],
  comboCategories: [],
  comboModified: [],
  sortedCategoryMenu: [],
  sortedComboMenu: [],
  showCategoryHomeMenu: false,
  menuVersion: null,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MENU_REQUEST:
      return {
        ...state,
        hotelKey: action.payload,
        // selectedCategory: null,
        searchPhrase: "",
        category: [],
        categoryLoaded: false,
      };
    case types.GET_MENU_RECEIVE:
      return {
        ...state,
        category: action.payload,
        sortedCategoryMenu: action.payload,
        categoryLoaded: true,
      };
    case types.GET_COMBO_MENU_RECEIVE:
      const sortedList = action.payload.sort((a, b) => {
        return a?.category_position - b?.category_position;
      });
      const comboCategories = [...new Set(sortedList)];
      let comboModified = [];
      comboCategories.forEach((cc) => {
        comboModified = {
          ...comboModified,
          [cc.category_name]: action.payload.filter(
            (c) => c?.category_name === cc.category_name
          ),
        };
      });
      return {
        ...state,
        comboCategories,
        combo: action.payload,
        categoryLoaded: true,
        comboModified,
        sortedComboMenu: comboModified,
      };
    case types.GET_MENU_CATEGORY_LIST_RECEIVE:
      return {
        ...state,
        selectedCategory:
          state.selectedCategory === action.payload ? null : action.payload,
      };
    case types.GET_MENU_ON_SEARCH:
      return {
        ...state,
        searchPhrase: action.payload,
      };
    case types.CLEAR_MENU:
      return {
        ...state,
        selectedCategory: null,
        category: [],
        categoryLoaded: false,
        searchPhrase: "",
      };
    case types.FILTER_MENU_CATEGORY:
      return {
        ...state,
        sortedCategoryMenu: action.payload,
      };
    case types.FILTER_MENU_COMBO:
      return {
        ...state,
        sortedComboMenu: action.payload,
      };
    case types.SET_MENU_VERSION:
      return {
        ...state,
        menuVersion: action.payload,
      };
    case types.SHOW_CATEGORY_HOME_MENU:
      return {
        ...state,
        showCategoryHomeMenu: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
