import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  customPopup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customText: {
    textAlign: "center",
    background: "#fff",
  },
  customAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionText: {
    fontWeight: "bold",
  },
  updateActionText: {
    fontWeight: "bold",
    backgroundColor: "#99d98c",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#b5e48c",
    },
  },
  placeholderImg: {
    maxHeight: "370px",
    width: "100%",
    margin: "5px",
  },
  imgDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
