import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
// Components
import ChoiceItem from "./../ChoiceItem/ChoiceItem";
// Icons
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
// Controls
import Btn from "./../Controls/Button/Button";
import Modal from "./../Material/Modal/Modal";
// Custom Hooks
import useDynamicHotelData from "../../CustomHooks/useDynamicHotelData";
import useCheckoutForm from "../../CustomHooks/useCheckoutForm";
// Styles
import useStyles from "./FilterBar.style.js";
import { generateDateSlots, getTimeIntervalSlots } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { SORT_OPTIONS } from "../../utils/Constants";
import useSortMainHook from "../../CustomHooks/useSortMainHook";
import { setNextAvailableTime } from "../../store/Time/TimeAction";
import moment from "moment";
import Menu from "../Menu/Menu";
import CategoryModal from "../CategoryModal/CategoryModal";
import { useRef } from "react";
import { Button, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import List from "@material-ui/icons/List.js";
import { getMenuCategoryList } from "../../store/Menu/MenuActions";

const TYPES = {
  ORDER_TYPE: "ORDER_TYPE",
  PAYMENT_METHOD: "PAYMENT_METHOD",
};

const FilterBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { FType, handleFilter } = useSortMainHook();
  const [drawer, toggleDrawer] = useState(false);
  const menu = useSelector((state) => state.menu);
  const [filterDrawer, toggleFilterDrawer] = useState(false);
  const [filterOrderType, setFilterOrderType] = useState("");
  const [openModal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [nextAvailable, setNextAvailable] = useState("");
  const { getOrderTypes, getPaymentMethods } = useDynamicHotelData();
  const { formValues, setFormValues } = useCheckoutForm();
  const { hotel, tableId } = useSelector((state) => state.hotel);
  const { cart } = useSelector((state) => state);
  const { nextAvailableTime } = useSelector((state) => state.time);
  // console.log("next availble time", nextAvailableTime)
  const [isCollapse, setIsCollapse] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedOrderType = useMemo(() => {
    if (getOrderTypes?.length === 1) {
      setFormValues({ orderType: getOrderTypes[0]?.type });
      const data = getOrderTypes[0]?.title;
      return data;
    } else if (getOrderTypes?.length > 1) {
      const data = getOrderTypes.find((o) => o.type === formValues.orderType);
      return data ? data.title : "";
    } else {
      setFormValues({ orderType: null });
    }
    // return data ? data.title : "";
  }, [formValues.orderType, getOrderTypes]);

  //order filter
  const onOpenDrawer = (type) => {
    setFilterOrderType(type);
    toggleDrawer(true);
  };
  const applyOrderFilter = (fieldName, val) => {
    setFormValues({ [fieldName]: val });
    localStorage.setItem("orderType", val);
    toggleDrawer(false);
  };
  //filter
  const onOpenFilterDrawer = (type) => {
    toggleFilterDrawer(true);
  };
  const applyFilter = (val) => {
    handleFilter(val);
    toggleFilterDrawer(false);
  };

  const setMenuCategory = (categoryId) => {
    // console.log("category iddddddddd",categoryId)
    dispatch(getMenuCategoryList(categoryId));
  };

  useEffect(() => {
    const dates = generateDateSlots(hotel, formValues.orderType) || [];
    const timeSlots =
      (dates?.length &&
        getTimeIntervalSlots(hotel, cart, formValues, dates[0].date)) ||
      [];
    if (dates.length && timeSlots.length) {
      const nextSlot = `${dates[0].label} ${timeSlots[0].label}`;

      // const nextSlot = dates
      // setNextAvailable(nextSlot);
      dispatch(setNextAvailableTime(nextSlot));
    }
  }, [formValues.orderType, nextAvailableTime, hotel]);

  function hasModalBeenShown() {
    return localStorage.getItem("categoryModalShown") === "true";
  }

  // console.log("historyyyyyy", history);

  useEffect(() => {
    if (hotel?.menu_popup_auto === 1 && !hasModalBeenShown()) {
      setModal(true);
      setIsCollapse(true);
      localStorage.setItem("categoryModalShown", "true");
    }
  }, [hotel]);

  return (
    <div className={clsx(classes.filterBarContainer, classes.bgColor)}>
      {hotel?.isCategoryBar === 1 ? (
        <div className={classes.categoryDiv}>
          <div className={isMobile ? classes.rightMobileDiv : classes.rightDiv}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setModal(true);
                setIsCollapse(true);
              }}
              startIcon={<List style={{ fontSize: "2rem" }} />}
              //  endIcon={endIcon || null}
              className={isMobile ? classes.catMobileBtn : classes.catBtn}
            />
            <Paper
              className={
                isMobile ? classes.scrollbarMobileDiv : classes.scrollableDiv
              }
            >
              {menu?.comboCategories
                ?.filter(
                  (item, index, self) =>
                    self.findIndex(
                      (i) => i?.category_name === item?.category_name
                    ) === index
                )
                .map((c) => (
                  <div
                    key={c.combo_id}
                    className={clsx(
                      classes.item,
                      selectedItem === c.category_name && classes.boldText
                    )}
                    onClick={() => {
                      setMenuCategory(c.category_name);
                      setSelectedItem(c.category_name);
                    }}
                  >
                    {c.category_name}
                  </div>
                ))}
              {menu.category.map((c, i) => (
                <div
                  key={c?.categoryId}
                  className={clsx(
                    classes.item,
                    selectedItem === c.categoryId && classes.boldText
                  )}
                  onClick={() => {
                    setMenuCategory(c.categoryId);
                    setSelectedItem(c.categoryId);
                  }}
                >
                  {c.label}
                </div>
              ))}
            </Paper>
          </div>
        </div>
      ) : (
        <>
          <div className={clsx("container", "p-0", classes.sectionDesktop)}>
            <Btn
              text={"Categories"}
              handleClick={() => {
                setModal(true);
                setIsCollapse(true);
              }}
              // handleClick={openModalBox}
              endIcon={<ExpandMoreOutlinedIcon />}
              className={classes.btn}
            />
            {hotel?.items_sorting ? (
              <Btn
                text={FType}
                endIcon={
                  filterDrawer ? (
                    <ExpandLessOutlinedIcon />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  )
                }
                className={classes.btn}
                onClick={() => onOpenFilterDrawer()}
              />
            ) : (
              ""
            )}
            {!tableId && (
              <Btn
                text={selectedOrderType || "Select Order Type"}
                endIcon={
                  drawer && filterOrderType === TYPES.ORDER_TYPE ? (
                    <ExpandLessOutlinedIcon />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  )
                }
                className={classes.btn}
                onClick={() => onOpenDrawer(TYPES.ORDER_TYPE)}
              />
            )}

            {tableId && (
              <Btn
                text={"Tableside order"}
                // endIcon={
                //   drawer && filterOrderType === TYPES.ORDER_TYPE ? (
                //     <ExpandLessOutlinedIcon />
                //   ) : (
                //     <ExpandMoreOutlinedIcon />
                //   )
                // }
                className={classes.btn}
                // onClick={() => onOpenDrawer(TYPES.ORDER_TYPE)}
              />
            )}

            {nextAvailableTime && (
              <Typography className={classes.nextAvailable} variant="h3">
                {nextAvailableTime}
              </Typography>
            )}
            {/* <CategoryModal
          open={openModal}
          handleClose={closeModal}
          triggerElement={buttonRef.current}
        /> */}
          </div>
          <div className={classes.sectionMobile}>
            {/* <Btn
          text={FType}
          endIcon={
            filterDrawer ? (
              <ExpandLessOutlinedIcon />
            ) : (
              <ExpandMoreOutlinedIcon />
            )
          }
          className={classes.btn}
          onClick={() => onOpenFilterDrawer()}
        /> */}
            {/* <Btn
          text={"View Menu"}
          handleClick={() => {
            setModal(true);
          }}
          endIcon={<ExpandMoreOutlinedIcon />}
          className={classes.mobileBtn}
        /> */}
            {!tableId && (
              <Btn
                text={selectedOrderType || "-"}
                endIcon={
                  drawer && filterOrderType === TYPES.ORDER_TYPE ? (
                    <ExpandLessOutlinedIcon />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  )
                }
                className={classes.btn}
                onClick={() => onOpenDrawer(TYPES.ORDER_TYPE)}
              />
            )}

            {tableId && (
              <Btn
                text={"Tableside order"}
                // endIcon={
                //   drawer && filterOrderType === TYPES.ORDER_TYPE ? (
                //     <ExpandLessOutlinedIcon />
                //   ) : (
                //     <ExpandMoreOutlinedIcon />
                //   )
                // }
                className={classes.btn}
                // onClick={() => onOpenDrawer(TYPES.ORDER_TYPE)}
              />
            )}
            {nextAvailableTime && (
              <Typography className={classes.nextAvailable} variant="h3">
                {nextAvailableTime}
              </Typography>
            )}
          </div>
        </>
      )}

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.filterDrawerContainer}>
          <Typography className={classes.filterDrawerTitle} variant="h3">
            {(filterOrderType === TYPES.ORDER_TYPE && "Im ordering for") ||
              (filterOrderType === TYPES.PAYMENT_METHOD && "Payment options")}
          </Typography>

          <Grid container spacing={2}>
            {filterOrderType === TYPES.ORDER_TYPE &&
              getOrderTypes.map((opt, i) => (
                <Grid item key={i} xs={12} sm={12}>
                  <ChoiceItem
                    // disabled={!opt.active}
                    isOrderType={true}
                    title={opt.title}
                    type={opt.type}
                    value={opt.type}
                    selected={formValues.orderType === opt.type}
                    handleClick={(val) => applyOrderFilter("orderType", val)}
                  />
                </Grid>
              ))}
            {filterOrderType === TYPES.PAYMENT_METHOD &&
              getPaymentMethods.map((opt, i) => (
                <Grid item key={i} xs={12} sm={12}>
                  <ChoiceItem
                    disabled={!opt.active}
                    title={opt.title}
                    type={opt.type}
                    value={opt.type}
                    selected={formValues.paymentMethod === opt.type}
                    handleClick={(val) =>
                      applyOrderFilter("paymentMethod", val)
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </Drawer>
      {/* filter drawer */}
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={filterDrawer}
        onClose={() => toggleFilterDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.filterDrawerContainer}>
          <Typography className={classes.filterDrawerTitle} variant="h3">
            Sort By
          </Typography>

          <Grid container spacing={2}>
            {SORT_OPTIONS?.map((f) => (
              <Grid item xs={12} sm={12} key={f.label}>
                <ChoiceItem
                  // disabled={!opt.active}
                  title={f.label}
                  type={f.label}
                  value={f.value}
                  selected={FType === f.value}
                  handleClick={(val) => applyFilter(val)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Drawer>
      <Modal
        title={"Main Menu"}
        open={openModal}
        handleClose={() => setModal(false)}
        isCollapse={isCollapse}
      >
        <Menu closeModal={() => setModal(false)} />
      </Modal>
    </div>
  );
};
export default FilterBar;
