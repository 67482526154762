import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    height: 0,
  },
  btnCon: {
    position: "absolute",
    textTransform: "none",
    zIndex: 1,
    bottom: 10,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    margin: "0 1rem",
    width: "150px",
    fontSize: theme.typography.pxToRem(12),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.05, 1.6),
    lineHeight: "18.79px",
    textTransform: "capitalize",
    ...theme.typography.rockwell,
  },
  spclBtn: {
    margin: "0 1rem",
    width: "250px",
    fontSize: theme.typography.pxToRem(12),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.05, 1.6),
    lineHeight: "18.79px",
    textTransform: "capitalize",
    ...theme.typography.rockwell,
  },
}));

export default useStyles;
