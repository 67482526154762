import * as types from "./CartTypes";

//Replace action name and update action types
export const updateCart = (item) => ({
    type: types.UPDATE_CART,
    payload: item,
});
export const processCart = (data) => ({
    type: types.PROCESS_CART,
    payload: data
});
export const deleteFromCart = (item) => ({
    type: types.DELETE_FROM_CART,
    payload: item,
});
export const saveCheckoutForm = (formValues) => ({
    type: types.SAVE_CHECKOUT_DETAILS,
    payload: formValues,
});
export const clearCart = () => ({
    type: types.CLEAR_CART,
});
export const savePriceSummary = (priceSummary) => ({
    type: types.PRICE_SUMMARY,
    payload: priceSummary,
});
export const getCoupon = (payload) => ({
    type: types.GET_COUPON_REQUEST,
    payload
});

export const getCouponReceive = payload => ({
    type: types.GET_COUPON_RECEIVE,
    payload
});

export const selectCoupon = payload => ({
    type: types.SELECT_COUPON,
    payload
});

export const clearCoupon = () => ({
    type: types.CLEAR_COUPON
});

export const setAvailableCoupons = (payload) => ({
    type: types.SET_AVAILABLE_COUPONS,
    payload
});

export const clearAvailableCoupons = () => ({
    type: types.CLEAR_AVAILABLE_COUPONS
});

export const validateCoupon = (payload) => ({
    type: types.VALIDATE_COUPON,
    payload
});

export const selectTable = (payload) => ({
    type: types.SELECT_TABLE,
    payload
});

export const clearTableSelection = () => ({
    type: types.CLEAR_TABLE_SELECTION
});

export const setGuestCount = (payload) => ({
    type: types.SET_GUESTS_COUNT,
    payload
});